import React from 'react'
import { Link } from 'gatsby'

import Image from './Image'
import './PressCard.css'
import Content from './Content'

const PressCard = ({
  engtitle,
  engtext,
  sptitle,
  sptext,
  engdate,
  spdate,
  engfeaturedvideo,
  slug,
  categories = [],
  className = '',
  frontmatter,
  ...props
}) => (
  <Link to={slug} className={`PressCard ${className}`}>
    <div className="PressCard--Content">
    <div className="PressCard--Meta">
            {engdate && (
              <time
                className="PressCard--Meta--Date"
                itemProp="dateCreated pubdate datePublished"
                date={engdate}
              >
                {engdate}
              </time>
            )}
          </div>
      {engtitle && <h3 className="PressCard--Title">{engtitle}</h3>}
      -----
      {sptitle && <h3 className="PressCard--Title">{sptitle}</h3>}
      <div className="PressCard--Preview">
        {engfeaturedvideo && 
                <div className="PressCard--Excerpt">
                  <iframe width="auto" height="auto" 
                  src={`https://www.youtube-nocookie.com/embed/${engfeaturedvideo}`}
                  frameborder="0" 
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                  allowfullscreen></iframe>
                  </div>}
        {engtext && <Content className="PressCard--Excerpt" source={engtext}></Content>} 
      </div>
    </div>
  </Link>
)

export default PressCard
